// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-danke-js": () => import("/Users/c.kehres/Projects/zvw/zvw-app.de/src/pages/danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-index-js": () => import("/Users/c.kehres/Projects/zvw/zvw-app.de/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/Users/c.kehres/Projects/zvw/zvw-app.de/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-purl-js": () => import("/Users/c.kehres/Projects/zvw/zvw-app.de/src/pages/purl.js" /* webpackChunkName: "component---src-pages-purl-js" */),
  "component---src-pages-testen-js": () => import("/Users/c.kehres/Projects/zvw/zvw-app.de/src/pages/testen.js" /* webpackChunkName: "component---src-pages-testen-js" */)
}

