import React, { Component } from 'react'
import PropTypes from 'prop-types'

const AppContext = React.createContext()

const AppConsumer = AppContext.Consumer

class AppProvider extends Component {
  state = {
    purl: null,
    setPurl: purl => {
      const isCompany = !!purl.contact.companyName

      let isDigitalSubscriber = false
      let isPrintSubscriber = false

      switch (purl.campaign._id) {
        case '5c064ea242ad341b4dc6351c': // bestehende Digital-Kunden
          isDigitalSubscriber = true
          isPrintSubscriber = false
          break
        case '5c065353b175731b4da0051e': // bestehende Print-Kunden (ohne E-Mail) - Gruppe 1
        case '5c3c3d47d325ee7e6c92c486': // bestehende Print-Kunden (mit E-Mail) - Gruppe 2
        case '5c3c3e95d325ee7e6c92c488': // bestehende Print-Kunden mit ehemals ePaper - Gruppe 3/6
          isDigitalSubscriber = false
          isPrintSubscriber = true
          break
        case '5c3c3d7dd325ee7e6c92c487': // ehemalige Digital-Kunden - Gruppe 4/5
          isDigitalSubscriber = false
          isPrintSubscriber = false
          break
      }

      this.setState({
        purl,
        isPersonalized: true,
        isCompany,
        isPerson: !isCompany,
        isDigitalSubscriber,
        isPrintSubscriber,
      })
    },
    isPersonalized: false,
    isPerson: null,
    isCompany: null,
    edition: null,
    isDigitalSubscriber: null,
    isPrintSubscriber: null,
    setIsPrintSubscriber: () => {
      this.setState({
        isPrintSubscriber: true,
        isDigitalSubscriber: false,
      })
    },
    setIsNoSubscriber: () => {
      this.setState({
        isPrintSubscriber: false,
        isDigitalSubscriber: false,
      })
    },
  }

  componentDidMount() {
    const hostname = window.location.hostname
      .replace('www.', '')
      .replace('danke.', '')

    switch (hostname) {
      case 'schorndorf-app.de':
        this.setState({ edition: 'schorndorf' })
        break
      case 'welzheim-app.de':
        this.setState({ edition: 'welzheim' })
        break
      case 'winnenden-app.de':
        this.setState({ edition: 'winnenden' })
        break
      case 'waiblingen-app.de':
        this.setState({ edition: 'waiblingen' })
        break
      default:
        this.setState({ edition: 'zvw' })
        break
    }
  }

  render() {
    const { children } = this.props

    return (
      <AppContext.Provider value={this.state}>{children}</AppContext.Provider>
    )
  }
}

AppProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

const withAppContext = Component => {
  const WrapperComponent = props => (
    <AppConsumer>
      {state => <Component {...props} appContext={state} />}
    </AppConsumer>
  )

  return WrapperComponent
}

export { AppContext, AppProvider, AppConsumer, withAppContext }
