import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { client } from './src/apollo/client'
import { AppProvider } from './src/contexts/AppContext'

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AppProvider>{element}</AppProvider>
  </ApolloProvider>
)

export const onClientEntry = () => {
  const [purl] = window.location.pathname.substring(1).split('/')

  if (purl && purl.includes('-')) {
    window.location.replace(
      window.location.protocol +
        '//' +
        window.location.host +
        '/purl' +
        window.location.pathname
    )
  }
}
