import { ApolloClient } from 'apollo-client'
import fetch from 'isomorphic-fetch'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

const httpLink = createHttpLink({
  uri: 'https://api-1.nbg1-dc3.hz.zvw-app.de/',
  fetch,
})

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})
